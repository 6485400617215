
.tableHead {
    background-color: #384A52;
}

.tableHead th {
    color: white;
}

.tableCell {
    padding: 8px;
}

.tableRow {
    border-bottom: none;
}

.smallTable {
    font-size: 0.875rem;
}

.smallTableCell {
    padding: 1px 4px;
    font-size: 0.875rem;
    border-bottom: none;

}

.smallTableRow {
    border-bottom: none;
}

.paper {
    background-color: #ffffff;
}

.button {
    margin-top: 10px;
}

.marginLeft {
    margin-left: 10px;
}

.MuiInputBase-root {
    border-radius: auto; 
    width: auto !important; 
    height: auto !important; 
    margin-top: auto; 
}