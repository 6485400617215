@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

:root {
    --black: #1E272E;
    --white: #fff;
    --primary: #3761ed;
}

[data-theme='dark'] body {
    background-color: #262626;
    color: white;
}

textarea {
    border: 1px solid #dbe1eb;
    color: #dbe1eb;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    /* flex-grow: 12px; */
    margin: 65px;
    padding: 18px 10px 10px 60px;
    margin-bottom: 0;
    width: auto;
}

.flex-center {
    display: flex;
    justify-content: center;
}


.flex-end {
    display: flex;
    justify-content: end;
}

.flex-align-center {
    display: flex;
    align-items: center;
    gap: 5px;
}

.flex-align-center-gap {
    display: flex;
    align-items: center;
    gap: 10px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*Chrome, Safari and Opera */
.edition-drawer .MuiPaper-root::-webkit-scrollbar {
    display: none;
}

/* IE, Edge and Firefox */
.edition-drawer .MuiPaper-root::-webkit-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.filters-container-toolbar .MuiInputBase-root {
    background: #ffffff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.filters-container-toolbar button {
    border-radius: 5px;
    text-transform: capitalize;
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    background: #636262;
    border-radius: 15px;
}

textarea {
    color: black;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border: 1px solid #0000003b;
    border-radius: 10px;
    font: unset;
    resize: none;
}

.right-card-information {
    height: 746px;
    overflow: auto;
}

.animation-badge-scaled .MuiBadge-badge {
    transform: scale(1.3);
}

.carousel-home-priv .carousel.carousel-slider,
.carousel-home-priv .slider-wrapper.axis-horizontal {
    max-height: 250px !important;
    height: auto;
}

.carousel-home-priv .control-dots {
    padding-right: 30px !important;
    text-align: end;
}

.carousel-home-priv .control-dots ul {
    text-align: end !important;
}

.carousel-home-priv .control-dots li {
    background: #4db8bd !important;
    width: 10px !important;
    height: 10px !important;
}

.control-arrow.control-next:hover,
.control-arrow.control-prev:hover {
    background: transparent !important;
}

.carousel-home-priv .control-arrow.control-next:before {
    color: var(--black) !important;
    content: '>';
    font-family: cursive;
    font-size: 35px;
    border: none;
}

.carousel-home-priv .control-arrow.control-prev:before {
    color: var(--black) !important;
    content: '<';
    font-family: cursive;
    font-size: 35px;
    border: none;
}

.MuiInputBase-root {
    border-radius: 5px !important;
    width: auto;
    min-height: 60px;
    /* margin-top: -15px; */
}

.text-link {
    font-size: 15px;
    cursor: pointer;
    color: #1976d2;
    text-transform: capitalize;
    user-select: none;
}

.text-link:active {
    transform: scale(0.9);
}

.MuiTable-root .Mui-selected {
    background-color: #e0ebed !important;
}

.MuiTable-root .Mui-selected td:hover {
    background-color: #e0ebed !important;
}

.MuiPickersCalendarHeader-label {
    text-transform: capitalize;
}

.notifications-disabled span {
    background: transparent;
    color: red;
    font-weight: 800;
    font-size: 16px;
    bottom: 4px;
    left: 4px;
}

.MuiTooltip-tooltip {
    background: var(--primary);
    font-weight: 600;
    font-size: 13px;
    border: 0.5px solid var(--white);
    border-radius: 10px;
}

.strong-btn button {
    text-transform: uppercase;
    font-weight: 600;
}

.info-card-title {
    font-size: 22px;
    margin: 0 15px;
}

.info-card-title::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 23px;
    object-fit: contain;
    background-color: var(--primary);
    margin-left: -8px;
    margin-top: 6px;
}

.btn-add-file {
    text-transform: capitalize;
    border-radius: 25px;
    color: #5074ef;
    font-size: 14px;
    border: 2px solid #5074ef;
    justify-content: flex-start;
}

/* Extra small devices (phones, 600px and down) -xs-*/
@media only screen and (max-width: 600px) {
    .container {
        margin: 25px 15px;
        padding: 40px 0;
    }
    .table-certificates {
        font-size: 11px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) -sm/md-*/
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 900px and 1199px) -md- */
@media only screen and (min-width: 900px) and (max-width: 1199px) {
    .container {
        margin: 65px 15px 0 65px;
        padding: 10px 0px 10px 40px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) -lg-*/
@media only screen and (min-width: 1200px) {
}

/* Small devices (portrait tablets and large phones, 600px and 900px) */
@media only screen and (min-width: 600px) and (max-width: 899px) {
    .container {
        margin: 65px 35px 0 65px;
        padding: 10px 0px 10px 40px;
    }
}
