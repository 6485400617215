.root {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url('/public/bglogin.jpg');
    background-size: cover;
    position: relative;
}

.greenBackground {
    background-color: #4caf4fa9; /* Verde */
}

.blueBackground {
    background-color: #2195f36c; /* Azul */
}

.overlayGreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(11, 63, 4, 0.8); */
    background-color: rgba(27, 46, 62, 0.8);

    z-index: 1;
}
.overlayBlue {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(23, 90, 182, 0.8); */
    background-color: rgba(27, 46, 62, 0.8);
    z-index: 1;
}

.formElement div:nth-child(2) {
    border-radius: 4px !important;
}

.formElement p {
    padding: 5px;
}

.btnSubmit {
    border-radius: 4px !important;
    height: 42px;
}

.btnText {
    text-transform: capitalize;
    text-decoration: underline;
    width: auto;
    justify-content: end;
    font-weight: 500;
    font-size: 16px;
}

.loginFormContainer {
    text-align: center;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.paperContainer {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: none;
    width: 580px;
    background-color: white;
    z-index: 2;
    padding: 40px 0px;
    max-height: 720px;
}

.newPasswordStyle p {
    width: 80%;
    display: inline-flex;
    justify-content: space-around;
    padding: 0;
    font-size: 13px;
}

.loginFormButtonsContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 15px;
}

.loginFormButtonsContainer .btnTextContainer button {
    padding: 0;
    color: #0c6600;
}

.loginFormButtons .btnSubmit {
    align-self: center;
}

.btnTextContainer {
    display: inline-flex;
    justify-content: flex-end;
}

.logoPrincipal {
    max-width: 190px;
    object-fit: contain;
    z-index: 2;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .btnSubmit,
    .btnTextContainer,
    .formElement {
        width: 100%;
    }

    .logoPrincipal {
        width: 100%;
        margin-bottom: 30px;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .loginFormContainer {
        padding: 16px;
    }
}
/* Medium devices (landscape tablets, 768px and up) -sm/md-*/
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 900px and up) -md- */
@media only screen and (min-width: 900px) {
    .formElement,
    .logoPrincipal,
    .btnTextContainer,
    .btnSubmit {
        width: 100%;
    }
    .loginFormContainer {
        width: 70%;
        padding: 32px;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) -lg-*/
@media only screen and (min-width: 1200px) {
}

/* Small devices (portrait tablets and large phones, 600px and 900px) */
@media only screen and (min-width: 600px) and (max-width: 899px) {
    .formElement,
    .logoPrincipal,
    .btnSubmit,
    .btnTextContainer {
        width: 100%;
    }
}
