.datatable tr:hover {
    background-color: #e0ebed;
}

.datatable-rows {
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #E0EBED;
}

.datatable-rows td:first-child {
    padding-left: 5px;
}

.datatable-rows td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 8px;
    box-sizing: border-box;
}

.datatable tr td button:last-child:hover {
    background-color: #fff;
}

.datatable-tr-td-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.datatable-tbody {
    font-size: 14px;
}

.element {
    text-align: center;
}

.table-tbody-align-right {
    display: table-row-group;
}

.table-tbody-align-right tr {
    display: table-row;
    justify-content: flex-end;
}

.table-tbody-align-left tr {
    display: table-row;
    justify-content: flex-start;
}

.table-tbody-align-right tr td {
    padding: 0 5px;
}

.table-tbody-align-right tr td:nth-child(2),
.table-tbody-align tr td:first-child,
.datatable-subtable tr:first-child td {
    font-weight: 700;
}

.datatable-subtable {
    width: 100%;
    table-layout: fixed;
    min-width: 125px;
}

.iconbtn-m {
    margin: 3px 5px;
    border: 1px solid #E0EBED;
    background-color: #fff;
    border-radius: 5px;
}

.iconbtn-active {
    border: 1.5px solid #1473E6;
    outline: 1.5px solid #1473E6;
}

@media only screen and (max-width: 600px) {
    .datatable-rows td {
        font-size: 12px;
    }
}
