.list-root {
    width: 100%;
    background: #ffffff;
    padding: 0;
    overflow: auto;
}

.list-primary-text {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
