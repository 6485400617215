.header-container {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    background-color: white;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 15px;
}

.drawer {
    justify-content: space-between;
}

.header-container h1 {
    font-size: 36px;
    font-weight: 700;
    padding-left: 10px;
    color: #1e272e;
}

/* .title::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 30px;
    object-fit: contain;
    background-color: var(--primary);
    margin-left: -8px;
    margin-top: 6px;
} */

.header-container h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 16px;
    margin-top: 16px;
}

.header-container-drawer .drawertitle {
    font-size: 20px;
    font-weight: 700;
}

/* .drawertitle::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 23px;
    object-fit: contain;
    background-color: var(--primary);
    margin-left: -8px;
    margin-top: 6px;
} */

.header-back-btn button {
    padding: 3px 4px;
    width: 100px;
    margin: 8px 0;
}

.header-container-drawer {
    width: 100%;
    display: inline-flex;
    align-items: center;
    margin-top: 30px;
    justify-content: flex-start;
}

@media only screen and (max-width: 600px) {
    .header-container h1 {
        font-size: 23px;
    }

    .title::before {
        height: 23px;
    }
}
