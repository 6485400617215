.collapseTitle p {
    font-size: 18px;
}

.collapseTitle p::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 27px;
    object-fit: contain;
    background-color: #0000ff;
    margin-left: -12px;
}
